import { WebStorageStateStore } from "oidc-client";

export const env = {
  production: true,
  contactName: '',
  contactNameUI: '',
  contactBusinessPhone: '',
  email: '',
  contactFirstName: '',
  contactLastName: '',
  CIDN: '',
  apiPoint: 'https://tapi.telstra.com/presentation/v1/infraco-portal/',
  aemEndPoint: './assets/labelAuthoring/',
  InfraCoCIDN:'9999999999',
  NBNCidn: '3805945066'
};

const userObj = {
  client_id: 'infraco-portal',
  response_type: 'code',
  scope: 'openid username profile roles',
  filterProtocolClaims: false,
  loadUserInfo: false,
  automaticSilentRenew: true,
  redirect_uri: 'https://infracoportal.telstra.com.au/',
  post_logout_redirect_uri: 'https://infracoportal.telstra.com.au/',
  silent_redirect_uri: 'https://infracoportal.telstra.com.au/silent-refresh.html',
  unauthorized_access_uri: 'https://infracoportal.telstra.com.au/unauthorised-access.html',
  userInfoEndPoint: 'https://myid.telstra.com/identity/idp/userinfo.openid',
  authority: 'https://myid.telstra.com/identity',
  userStore: new WebStorageStateStore({ store: window.localStorage })
}

window['environment'] = env;
window['userManagerObj'] = userObj;
export const environment = window['environment'] || env ;
export const userManagerObj = window['userManagerObj'] || userObj;